import { css, LitElement } from "lit";
import prebidAuction from "../helpers/prebid-auction";
import { partition } from "../helpers/partition-array";
import getContextConfig from "../helpers/get-context-config";
import { getCookie } from "../helpers/cookie-helper";
import badEvents from "../static/bad-events";
import noScaleLineitems from "../../../bonnier-ad-utils/lib/static/lineItemsToNotScale";
import { getSlotNumber } from "../helpers/get-slot-number";

const config = getContextConfig();
const articleCategoriesMap = {};

async function fetchCategories(slotElement) {
  // Bibblan has namespaced ids per site, we only want to use the last (id) part
  const articleId = slotElement.closest("article")?.dataset.uuid.split(".").pop();
  if (!articleId) throw new Error("No articleId found on the slot element");

  if (articleId in articleCategoriesMap) return articleCategoriesMap[articleId];

  try {
    const response = await fetch(`https://assets.bonad.io/categories/${articleId.substring(0, 3)}/${articleId}.json`);
    const data = await response.json();
    articleCategoriesMap[articleId] = data.NITF;
  } catch (e) {
    console.warning("Failed to fetch categories for article", articleId, e);
  }
  return articleCategoriesMap[articleId];
}


const intersectionObserver = new IntersectionObserver(
  (entries) => {
    entries
      .filter((entry) => entry.isIntersecting || entry.intersectionRatio > 0)
      .forEach((entry) => {
        entry.target.connectedCallback();
        intersectionObserver.unobserve(entry.target);
      });
  }, {
  rootMargin: "500%" // this is high as googleLazyLoad takes care of the rest of the logic.
  }
);

function parseSlotSize(slotSizeString) {
  const [w, h] = slotSizeString.split("x", 2).map((n) => parseInt(n, 10));
  return [w, h];
}

export default class BNAdElement extends LitElement {
  // This is not default set, is it a bug or should this be set differently?
  static styles = css`
    b-a-d {
      display: flex;
      justify-content: center;
    }
  `;

  static properties = {
    slot: { state: true },
    slotName: { type: String },
    slotNumber: { type: Number },
    slotSizes: {
      type: Array,
      converter: {
        fromAttribute: (value) => {
          const stringSizes = value.split(/,\s*/g);
          const sizes = stringSizes.map((size) => size.split("x").map(Number));
          const [validSizes, invalidSizes] = partition(sizes, (size) => size.length === 2 && !size.some(isNaN) && size.every((n) => n > 0));

          if (invalidSizes.length > 0) console.error("Invalid slot sizes: ", ...invalidSizes);

          return validSizes;
        },
        toAttribute: (value) => value.map(([x, y]) => `${x}x${y}`).join(","),
      },
    },
    impressionViewed: { type: Boolean, state: true },
    targetingTags: {
      attribute: "targeting-tags",
      converter: {
        fromAttribute: (value) => value.split(/\s*,\s*/g),
        toAttribute: (value) => value.join(","),
      },
    },
    targetingTa: {
      type: String,
      attribute: "targeting-ta",
    },
    targetingCategory: {
      type: Array,
      attribute: "targeting-category",
      converter: {
        fromAttribute: (value) => value.split(/\s*,\s*/g),
        toAttribute: (value) => value.join(","),
      },
    },
  };

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();

    // if the slot is not visible, we will wait for it to be visible before google lazy loading it.
    if (typeof this.checkVisibility === "function" ? !this.checkVisibility() : this.offsetParent === null) {
      return intersectionObserver.observe(this)
    };

    const { slotNameNumber, slotNameNumberTargeting } = getSlotNumber(this.slotName, this.slotNumber);

    this.id = `${this.slotName}-${slotNameNumber}`;
    this.slotNumber = slotNameNumberTargeting;
    this.pos = `${this.slotName}${this.slotNumber}`;

    const slotConfig = window.bamData.slotNameConfig[this.slotName];

    if (!slotConfig) return this.disconnectedCallback();

    const slotSizes = this.slotSizes || slotConfig.slots[this.slotNumber - 1]?.sizes || slotConfig.slots[0].sizes;

    if (!["panorama_top", "mob_storyline"].includes(this.slotName) && !slotSizes.includes("fluid") && !(this.slotName.includes("outsider") && window.bamData.path.includes("/bp/"))) {
      slotSizes.push("fluid");
    }

    // logic for rich_media_premium & mob_rich_media_premium
    if (this.slotName === "rich_media_premium" || this.slotName === "mob_rich_media_premium") {
      if (getCookie("bad-freq-rmp")) {
        return this.remove();
      }
    }

    googletag.cmd.push(() => {
      const targetings = {
        _ta_: this.targetingTa,
        tags: this.targetingTags,
        category: this.targetingCategory,
      };

      // filter out the undefined values
      for (const key in targetings) {
        targetings[key] === undefined && delete targetings[key]
      }

      this.slot = googletag
        .defineSlot(bamData.path, slotSizes, this.id)
        .updateTargetingFromMap({
          ...targetings,
          pos: this.pos,
          slotName: this.slotName,
          slotNameNo: this.slotNumber,
          reportKey: `${config.device?.[0] || "u"}/${config.pageType.substring(0, 3)}/${this.slotName}/${this.slotNumber}/${config.abTest || 0}`,
        })
        .addService(googletag.pubads());

      fetchCategories(this)
        .then((categories) => this.slot.setTargeting("categories", categories))
        .catch(() => {});

      if (this._pbjsScriptExistInHead() && window.bamData.prebidConfig?.prebidEnabled) {
        prebidAuction(this.slot, this._buildPrebidData(), () => {
          googletag.display(this.id);
        });
      } else {
        googletag.display(this.id);
      }

      this.setAttribute("impressionViewed", false);
    });

    this.addEventListener("bad:slotRenderEnded", this._slotRenderEnded);

    this.addEventListener("bad:impressionViewable", (event) => {
      event.target.setAttribute("impressionViewed", true);
    });
  }

  _resize() {
    const safeArea = {
      desktop: {
        width: 1920,
        height: 817 // 777 + 40
      },
      mob: {
        width: 640,
        height: 793 // 753 + 40
      }
    };

    let maxWidthScale;
    let maxHeightScale;

    if (this.slotName === "rich_media_premium") {
      maxWidthScale = window.visualViewport.width / safeArea.desktop.width;
      maxHeightScale = (window.visualViewport.height - this.getBoundingClientRect().y) / safeArea.desktop.height;
    } else {
      maxWidthScale = window.visualViewport.width / safeArea.mob.width;
      maxHeightScale = (window.visualViewport.height - this.getBoundingClientRect().y) / safeArea.mob.height;
    }

    const scale = Math.min(maxHeightScale, maxWidthScale);

    this.style.display = "flex";
    this.style.justifyContent = "center";

    this.lastChild.style.transformOrigin = "top";
    this.lastChild.style.transform = `scale(${scale})`;
  }

  _slotRenderEnded(event) {
    const detail = event.detail;

    if (detail.isEmpty) {
      this.dataset.adEmpty = "true";
      this.style.display = "none";

      // dispatch bad empty slot event for site to manually handle the removal of the div.
      this.dispatchEvent(new CustomEvent(badEvents.COLLAPSE, { bubbles: true, detail: { slot: this.slot } }));

      return;
    }

    if (detail.isBackfill) {
      this.dataset.backfill = "true";
    }

    if (this.slotName === "rich_media_premium" || this.slotName === "mob_rich_media_premium") {
      // Unique logic for previewing ads with a generated GAM preview link.
      if (detail.lineItemId === null && new URLSearchParams(location.href).get("lineItemId")) {
        detail.lineItemId = Number(new URLSearchParams(location.href).get("lineItemId"));
      }

      // if the ad is in 'noScaleLineitems', we do not scale it. We also check that the ad is not a "fluid-template" ad.
      if (!noScaleLineitems.includes(detail.lineItemId) &&  !this.slot.getHtml().includes("8b38f539-3ae1-4df4-af53-b2c9013bbe59")) {
        this._resize(detail);
        window.addEventListener("resize", this._resize.bind(this));
      } else {
        this.style.display = "flex";
        this.style.justifyContent = "center";

        const maxWidthScale = window.visualViewport.width / detail.size[0];
        const maxHeightScale = window.visualViewport.height / detail.size[1];

        const scale = Math.min(maxHeightScale, maxWidthScale, 1);

        this.lastChild.style.transform = `scale(${scale})`;
        this.lastChild.style.transformOrigin = "center top";
      }

      if (window.bamData.frequencyCookie?.expire) {
        document.cookie = `bad-freq-rmp=1; max-age=${window.bamData.frequencyCookie.expire * 60 * 60}; path=/`;
      } else {
        document.cookie = `bad-freq-rmp=1; max-age=${4 * 60 * 60}; path=/`;
      }
    } else if (
      this.parentElement.style.display === "" &&
      this.parentElement.style.justifyContent === "" &&
      this.style.display === "" &&
      this.style.justifyContent === ""
    ) {
      this.style.display = "flex";
      this.style.justifyContent = "center";
    }

    if (detail.campaignId === 2771767335) {
      // We know it's a prebid ad, add logic here if need be. Otherwise do nothing.
    }

    this.dataset.advertiserId = detail.advertiserId?.toString();
    this.dataset.campaignId   = detail.campaignId?.toString();
    this.dataset.creativeId   = (detail.creativeId || detail.sourceAgnosticCreativeId)?.toString();
    this.dataset.lineItemId   = (detail.lineItemId || detail.sourceAgnosticLineItemId)?.toString();

    let [width, height] = typeof detail.size === "string" ? parseSlotSize(detail.size) : detail.size;

    if (width === 0 || height === 0) {
      const [sizeTargeting] = detail.slot.getTargeting("hb_size");

      if (sizeTargeting) {
        [width, height] = parseSlotSize(sizeTargeting);
        this.width = width;
        this.height = height;
      }
    }
    // We say more than 1px as we have targeting pixels that are 1x1
    if (width > 1 && height > 1) {
      this.style.width = width + "px";
      this.style.height = height + "px";
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    googletag.cmd.push(() => googletag.destroySlots([this.slot]));
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return this.firstChild;
  }

  _pbjsScriptExistInHead() {
    return document.querySelector("[src*='rubiconproject.com/prebid'][type='text/javascript']");
  }

  _buildPrebidData() {
    const prebidData = {
      xandrData: {
        categories: this.targeting?.categories,
      },
      inventory: {
        categories: this.targeting?.categories,
      },
    };

    if (!bamData.disablePersonalizedAds) {
      const segments = googletag.pubads().getTargeting("bi")

      if (segments.length > 0) {
        prebidData.inventory.segments = segments;
        prebidData.visitors = { segment: segments };
        prebidData.mkv = segments.map((segment) => `segment=${segment}`).join(",");
      }
    }

    return prebidData;
  }
}
