import { fetchBongeoTargeting, fetchElmerTargeting } from "./targeting-handler";
import getContextConfig from "./get-context-config";
import validateKeyValue from "./validate-key-value-targetings";
import { validAudienceConsent } from "./consent";
import { onUrlUpdate } from "./url-updater";

const contextWhitelist = ["device", "_ta_", "pageType", "articleId", "tags", "category", "isLoggedIn", "isPayingCustomer", "accountLevel", "abTest", "theProgram", "ppid"];

const siteConfig = getContextConfig();

function setPageTargetings() {
  for (const [key, value] of Object.entries(siteConfig)) {
    if (contextWhitelist.includes(key)) {
      if (!validateKeyValue(key, value)) {
        console.warn(`invalid targeting key/value: ${key}/${value}`);
        continue;
      }
      googletag.pubads().setTargeting(key, value);
    }
  }
}

// function setupContextTargetings() {
//   fetchContextTargeting((err, contextData) => {
//     if (err) return;

//     // When data collection is done & ready to be used, then we do like this probably.

//     // googletag.cmd.push(() => {
//     //   for (const [key, value] of contextData) {
//     //     googletag.pubads().setTargeting(key, value)
//     //   }
//     // })
//   });
// }

function setupPageTargetings() {
  // external services
  fetchBongeoTargeting((err, bongeoData) => {
    if (err || Object.values(bongeoData).length === 0) return;

    googletag.cmd.push(() => {
      googletag.pubads().setTargeting("bontarg", Object.values(bongeoData));
    });
  });

  if (window.Didomi && validAudienceConsent(Didomi.getUserStatus())) {
    fetchElmerTargeting((err, elmerData) => {
      if (err) return;

      if (elmerData.marketing_segments.length > 0) {
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting("bi", elmerData.marketing_segments.join(","));
        });
      }
    });
  }

  // setupContextTargetings()

  // Page targetings that does not require any external requests
  googletag.cmd.push(() => {
    const url = location.hostname + location.pathname;
    const urlParts = url.split("/").filter(Boolean);

    googletag.pubads().setTargeting("url", url);
    googletag.pubads().setTargeting("urlParts", urlParts);
    googletag.pubads().setTargeting("tcf", "1");

    setPageTargetings();

    // If eventual query params are present, add them as page targetings, these are not validated
    const queryParams = new URLSearchParams(location.search);

    for (const [key, value] of queryParams.entries()) {
      googletag.pubads().setTargeting(key, value);
    }

    onUrlUpdate(async (e) => {
      const navigationUrl = new URL(e.destination ? e.destination.url : location.href);
      const url = navigationUrl.hostname + navigationUrl.pathname;
      const urlParts = url.split("/").filter(Boolean);

      googletag.pubads().setTargeting("url", url);
      googletag.pubads().setTargeting("urlParts", urlParts);

      // setupContextTargetings()
      setPageTargetings();

      // if meta tag hostname is present, we do not want to fetch bam data again.
      if (document.querySelector("meta[name='bad:hostname']")) return;

      window.bamData = await fetch(`https://bam.bonad.io?url=${url}`).then((response) => response.json());
    });
  });
}

export { setupPageTargetings };
