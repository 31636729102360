import { getCookie } from "./cookie-helper";

function fetchElmerTargeting(cb) {
	const clientId = getCookie("bnacid");
	if (!clientId) return;

	const url = `https://elmer.bonniernews.se/v1/segment/${clientId}?purpose=personalized-ads`;

	const options = {
		headers: {
			"x-bn-origin": `bad${window.location?.hostname !== 'localhost' ? `:${location.hostname}` : ''}`, //send the origin of the request for better elmer tracking.
		}
	};

	fetch(url, options)
		.then((response) => response.ok && response.json())
		.then((data) => {
			cb(null, data);
		})
		.catch((error) => {
			cb(error);
		});
}

function appendCurrentPositionToUrl(baseUrl, callback) {
	if (window.navigator && window.navigator.geolocation && window.navigator.permissions) {
		window.navigator.permissions
			.query({ name: "geolocation" })
			.then((result) => {
				if (result.state === "granted") {
					window.navigator.geolocation.getCurrentPosition((position) => {
						const { latitude, longitude } = position.coords;

						return callback(`${baseUrl}?latitude=${latitude}&longitude=${longitude}`);
					});
				} else {
					return callback(baseUrl);
				}
			})
			.catch(() => {
				callback(baseUrl);
			});
	} else {
		return callback(baseUrl);
	}
}

function fetchBongeoTargeting(cb) {
	const url = process.env.BONGEO_URL;
	appendCurrentPositionToUrl(url, (url) => {
		fetch(url)
			.then((response) => response.ok && response.json())
			.then((data) => {
				cb(null, data);
			})
			.catch((error) => {
				cb(error)
			});
	});
}

// function fetchContextTargeting(cb) {
// 	const url = process.env.CONTEXT_URL;

// 	// try to find the last articleId that exist.
// 	const articleId = [...window.document.querySelectorAll("article")]
// 		.filter((it) => it.dataset.uuid)
// 		.at(-1)?.dataset.uuid;

// 	appendCurrentPositionToUrl(url, (url) => {
// 		if (articleId) url.includes("?") ? url += `&articleId=${encodeURIComponent(articleId)}` : url += `?articleId=${encodeURIComponent(articleId)}`

// 		fetch(url)
// 			.then((response) => response.ok && response.json())
// 			.then((data) => {
// 				cb(null, data);
// 			})
// 			.catch((error) => {
// 				cb(error)
// 			});
// 	})
// }

export { fetchElmerTargeting, fetchBongeoTargeting }